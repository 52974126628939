// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../../../../../node_modules/@wix/fedops-logger/types.d.ts"/>
import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import {
	LoggerSymbol,
	ILogger,
	Interaction,
	Phase,
	LoggerIntegrations,
	ViewerModel,
	FedopsStore,
} from '@wix/thunderbolt-symbols'
import { Environment } from '../../types/Environment'

const getBiStore = (viewerSessionId: string, viewerModel: ViewerModel): FedopsStore => {
	const {
		site: { metaSiteId, sessionId, dc },
		rollout: { isDACRollout, siteAssetsVersionsRollout },
		fleetConfig: { code },
	} = viewerModel
	return {
		msid: metaSiteId,
		sessionId,
		viewerSessionId,
		requestId: viewerModel.requestId,
		dc,
		is_rollout: code === 0 || code === 1 ? !!code : null,
		is_dac_rollout: isDACRollout ? 1 : 0,
		is_sav_rollout: siteAssetsVersionsRollout ? 1 : 0,
		isCached: process.env.browser ? window.fedops.is_cached : false,
		is_headless: false,
		siteMemberId: undefined,
	}
}

const createConsoleLogger = () => ({
	runAsyncAndReport: <T>(asyncMethod: () => Promise<T> | T, methodName: string) => {
		console.log(`${methodName}`)
		return Promise.resolve(asyncMethod())
	},
	reportAsyncWithCustomKey: <T>(asyncMethod: () => Promise<T>, methodName: string, key: string) => {
		console.log(`${methodName} ${key}`)
		return Promise.resolve(asyncMethod())
	},
	runAndReport: <T>(fn: () => T, methodName: string) => {
		console.log(`${methodName}`)
		return fn()
	},
	phaseMark: console.log,
	appLoaded: () => console.log('appLoaded'),
	reportAppLoadStarted: console.log,
	captureError: (...args: any) => {
		console.error(...args)
	},
	setGlobalsForErrors: (/* {tags, extras} = {}*/) => {},
	breadcrumb: (/* messageContent, additionalData = {}*/) => {},
	interactionStarted: console.log,
	interactionEnded: console.log,
})

const shouldFilter = (url: string, message: string) => url && message

export function createLogger(loggerIntegrations: LoggerIntegrations): ILogger {
	const { fedopsCreator, sentryInstance, wixBiSession, viewerModel } = loggerIntegrations
	const mode = viewerModel && viewerModel.mode ? viewerModel.mode : { qa: true }
	if (mode.qa) {
		return createConsoleLogger()
	}
	const { viewerSessionId } = wixBiSession
	const biStore = getBiStore(viewerSessionId, viewerModel)
	const fedopsLogger = fedopsCreator({
		biStore,
		config: {
			phasesConfig: 'SEND_ON_START',
			appName: viewerModel.site && viewerModel.site.isResponsive ? 'thunderbolt-responsive' : 'thunderbolt',
		},
	})

	const loadInstance = () => {
		if (!process.env.browser) {
			return sentryInstance
		}
		// @ts-ignore
		sentryInstance.forceLoad()
		return sentryInstance
	}

	const captureError = (e: Error, { tags, extras, groupErrorsBy = 'tags' }: any = {}) =>
		loadInstance().withScope((scope: any) => {
			const fingerprints = []
			for (const key in tags) {
				if (tags.hasOwnProperty(key)) {
					scope.setTag(key, tags[key])
					if (groupErrorsBy === 'tags') {
						fingerprints.push(key)
					} else if (groupErrorsBy === 'values') {
						fingerprints.push(tags[key])
					}
				}
			}

			for (const key in extras) {
				if (extras.hasOwnProperty(key)) {
					scope.setExtra(key, extras[key])
				}
			}

			if (fingerprints.length) {
				scope.setFingerprint(['{{ default }}', ...fingerprints])
			}
			sentryInstance.captureException(e)
			// @ts-ignore
			console.log(e) // Sentry capture exception swallows the error
			fedopsLogger.interactionStarted('error') // this is a workaround to get error rate until we will have support for postgresSQL in fedonomy
		})
	const phaseMark = (phase: Phase) => fedopsLogger.appLoadingPhaseStart(phase)
	if (process.env.browser) {
		window.phaseMark = phaseMark
	}

	return {
		reportAsyncWithCustomKey: <T>(asyncMethod: () => Promise<T>, methodName: string, key: string): Promise<T> => {
			// @ts-ignore FEDINF-1937 missing type
			fedopsLogger.interactionStarted(methodName, { customParam: key })
			return asyncMethod()
				.then(
					(res): Promise<T> => {
						// @ts-ignore FEDINF-1937 missing type
						fedopsLogger.interactionEnded(methodName, { customParam: key })
						return Promise.resolve(res)
					}
				)
				.catch((error) => {
					captureError(error, { tags: { methodName } })
					return Promise.reject(error)
				})
		},
		runAsyncAndReport: async <T>(
			asyncMethod: () => Promise<T>,
			methodName: string,
			reportExeception: boolean = true
		): Promise<T> => {
			try {
				fedopsLogger.interactionStarted(`${methodName}`)
				const fnResult = await asyncMethod()
				fedopsLogger.interactionEnded(`${methodName}`)
				return fnResult
			} catch (e) {
				if (reportExeception) {
					captureError(e, { tags: { methodName } })
				}
				throw e
			}
		},
		runAndReport: <T>(method: () => T, methodName: string): T => {
			fedopsLogger.interactionStarted(methodName)
			try {
				const t = method()
				fedopsLogger.interactionEnded(methodName)
				return t
			} catch (e) {
				captureError(e, { tags: { methodName } })
				throw e
			}
		},
		captureError,
		setGlobalsForErrors: ({ tags, extra, groupErrorsBy } = { groupErrorsBy: 'tags' }) =>
			sentryInstance.configureScope((scope: any) => {
				scope.addEventProcessor((event: any, hint: any) => {
					const { url } = event.request
					const { message } = hint.originalException
					const fingerprints = []

					if (shouldFilter(url, message)) {
						return null
					}

					for (const key in tags) {
						if (tags.hasOwnProperty(key)) {
							scope.setTag(key, tags[key])
							if (groupErrorsBy === 'tags') {
								fingerprints.push(key)
							} else if (groupErrorsBy === 'values') {
								fingerprints.push(tags[key])
							}
						}
					}

					if (extra) {
						event.extra = event.extra || {}
						Object.assign(event.extra, extra)
					}

					if (fingerprints.length) {
						scope.setFingerprint(['{{ default }}', ...fingerprints])
					}

					return event
				})
			}),
		breadcrumb: (messageContent, additionalData = {}) =>
			sentryInstance.addBreadcrumb({
				message: messageContent,
				data: additionalData,
			}),
		interactionStarted: (interaction: Interaction) => fedopsLogger.interactionStarted(interaction),
		interactionEnded: (interaction: Interaction) => fedopsLogger.interactionEnded(interaction),
		phaseMark,
		reportAppLoadStarted: () => fedopsLogger.appLoadStarted(),
		appLoaded: () => fedopsLogger.appLoaded(),
	}
}

export const site = ({ logger }: Environment): ContainerModuleLoader => (bind) => {
	bind(LoggerSymbol).toConstantValue(logger)
}
